import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Home from "./pages/home/home";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import View from "./pages/view/view";
import News from "./pages/news/news";
import Projekte from "./pages/projekte/projekte";
import Biografie from "./pages/biografie/biografie";
import Impressum from "./pages/impressum/impressum";
import Datenschutz from "./pages/datenschutz/datenschutz";
import Contact from "./pages/contact/contact";
import NotFound from "./pages/notfound/notfound";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {

  const Layout = () => {
    return (
      <div className="site">
        <Navbar />
        <ScrollToTop />
        <Outlet />
        <Footer />
      </div>
    )
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/posts/:cat",
          element: <News />,
        },
        {
          path: "/post/:id",
          element: <View />,
        },
        {
          path: "/projekte",
          element: <Projekte />,
        },
        {
          path: "/biografie",
          element: <Biografie />,
        },
        {
          path: "/impressum",
          element: <Impressum />,
        },
        {
          path: "/datenschutz",
          element: <Datenschutz />,
        },
        {
          path: "post/:id/anfrage",
          element: <Contact />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
    {
      path: "/admin",
      element: <div>Admin Panel</div>,
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
